<template>
  <div class="auth-form mx-auto width-narrow mb-5">
    <div class="card mx-auto mb-5">
      <div class="card-body">
        <h1 class="heading-bigger text-center mb-4">
          Password Change
        </h1>
        <f-alert
          v-if="authError"
          theme="danger"
          visible
          class="my-4">
          <template v-if="errorText">
            {{ errorText }}
          </template>
          <template v-else>
            An error has occurred. Please try again.
          </template>
        </f-alert>

        <f-alert
          v-if="resetSuccess"
          theme="success"
          visible
          class="my-4">
          Password changed succesfully.
        </f-alert>

        <validation-observer
          v-if="!resetSuccess && tokenConfirmed"
          ref="password-recovery-form"
          tag="form">
          <validation-provider
            rules="required|min:8|max:50"
            name="password"
            v-slot="{ errors }">
            <div class="form-group">
              <!-- <label for="password" class="for-required">
                Password:
              </label> -->
              <input
                class="full-width"
                type="password"
                :state="errors.length ? 'invalid' : null"
                v-model="password"
                placeholder="Password" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <validation-provider
            rules="required|min:8|max:50|password:password"
            name="password-confirmation"
            v-slot="{ errors }">
            <div class="form-group">
            <!-- <label for="password-confirmation" class="for-required">
              Confirm Password:
            </label> -->
              <input
                class="full-width"
                type="password"
                placeholder="Password Confirmation"
                :state="errors.length ? 'invalid' : null"
                v-model="password_confirmation" />
              <div class="invalid-feedback">
                {{ errors[0] }}
              </div>
            </div>
          </validation-provider>

          <f-button
            type="submit"
            theme="secondary"
            class="block mx-auto mt-3 text-uppercase"
            @click.prevent="validateForm"
            :disabled="submitting">
            Send
          </f-button>
        </validation-observer>

        <div class="text-center mt-4">
          <f-button
            theme="text"
            @click.native="$router.push('/login')"
            class="lnk lnk-big lnk-big-blue"
            :disabled="submitting">
            Back to login page
          </f-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue';
import FormUtils from '@/utils/FormUtils.js';

export default {
  name: 'password-recovery',
  computed: {
    token () {
      if (this.$route.query && this.$route.query.token) {
        return this.$route.query.token;
      }

      Vue.set(this, 'authError', true);
      Vue.set(this, 'errorText', 'The generated password reset link is invalid.');

      return ''
    }
  },
  data () {
    return {
      authError: false,
      errorText: '',
      password: '',
      password_confirmation: '',
      resetSuccess: false,
      submitting: false,
      tokenConfirmed: false
    }
  },
  mounted () {
    Vue.nextTick(() => {
      this.validateToken();
    });
  },
  methods: {
    validateForm () {
      FormUtils.validate(this.$refs['password-recovery-form'], this.resetPassword);
    },
    validateToken () {
      this.authError = false;
      this.submitting = true;
      delete this.$http.defaults.headers['X-Csrf-Token'];

      this.$http.post('/password-recovery/validate', {
        token: this.token
      }).then((response) => {
        this.submitting = false;
        if (response.data.success === 1) {
          this.tokenConfirmed = true;
        } else {
          this.authError = true;
        }
      }).catch((error) => {
        console.log(error);
        this.submitting = false;
        this.authError = true;
      });
    },
    resetPassword () {
      this.authError = false;
      this.submitting = true;

      this.$http.post('password-recovery/change-password', {
        token: this.token,
        password: this.password,
        password_confirmation: this.password_confirmation
      }).then((response) => {
        this.submitting = false;
        if (response.data.success === 1) {
          this.resetSuccess = true;
        } else {
          this.authError = true;
        }
      }).catch((error) => {
        console.log(error);
        this.submitting = false;
        this.authError = true;
      });
    }
  }
}
</script>
